import { ReactComponent as ChevronIcon } from 'assets/icons/rightChevron.svg'

import {
  Button, FormControlLabel, Radio, RadioGroup,
} from '@mui/material'
import { ReactComponent as StopIcon } from 'assets/icons/stop.svg'
import terms from 'assets/localize/terms'
import CustomAutocomplete from 'components/autocomplete/Autocomplete'
import TextInput from 'components/textInput/TextInput'
import TrainDetails from 'components/trainDetails/TrainDetails'
import { useAppDispatch, useAppSelector } from 'hooks'
import { PanelSignal } from 'pages/snci/views/PanelsManager'
import { useEffect, useState } from 'react'
import { NetworksState } from 'reducers/networks/slices'
import { setDriverCommunication, setFormError } from 'reducers/snci/slices'
import { formatToReadablePk } from 'services/snci'
import { TrainManagerSocketMessage } from 'types/websocket'
import MovingConditions from '../movingConditions/MovingConditions'
import CrossingAuthorization from './crossingAuthorization/CrossingAuthorization'
import './DriverCommunication.scss'
import MovementInit from './movementInit/MovementInit'

type Props = {
  id: string
}

export const PK_RULE = /^[0-9]{1,3},[0-9]{3}$/

type StopRequest = 'pk' | 'signal' | 'cancellation'

export default function DriverCommunication({ id }: Props) {
  const dispatch = useAppDispatch()
  const { trainWebsocket, formError, driverCommunication } = useAppSelector(state => state.snci)
  const { objects: networkObjects } = useAppSelector(state => state.networks as NetworksState)
  const [stopRequestType, setStopRequestType] = useState<StopRequest>(undefined)
  const [stopRequest, setStopRequest] = useState('')

  useEffect(() => {
    if (!stopRequest) {
      if (driverCommunication?.stopPK) {
        setStopRequestType('pk')
        setStopRequest(driverCommunication.stopPK.replace('_', ','))
        return
      }
      if (driverCommunication?.stopSignal) {
        setStopRequestType('signal')
        setStopRequest(driverCommunication.stopSignal)
      }
    } else if (stopRequestType === 'cancellation' && !driverCommunication?.stopPK && !driverCommunication?.stopSignal) {
      // todo refactor
      setStopRequestType(undefined)
      setStopRequest('')
    }
  }, [driverCommunication])

  useEffect(
    () => () => {
      dispatch(setDriverCommunication(undefined))
      dispatch(setFormError(undefined))
    },
    [],
  )

  const goBack = () => {
    PanelSignal.value = <TrainDetails id={id} />
  }

  const hasStopRequest = () => !!driverCommunication?.stopPK || !!driverCommunication?.stopSignal

  const sendStopMessage = () => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: TrainManagerSocketMessage.emergencyStop,
        trainId: id,
      }),
    )
  }

  const sendStopRequest = () => {
    if (hasStopRequest()) {
      trainWebsocket?.current?.send(
        JSON.stringify({
          messageType: TrainManagerSocketMessage.stopCancellation,
          trainId: id,
        }),
      )
      setStopRequestType('cancellation')
      return
    }

    const val = stopRequest.replace(',', '_')

    const params = stopRequestType === 'pk'
      ? { messageType: TrainManagerSocketMessage.stopAtPk, pk: val }
      : { messageType: TrainManagerSocketMessage.stopAtSignal, signalName: val }

    trainWebsocket?.current?.send(
      JSON.stringify({
        trainId: id,
        consumerSpecific: true,
        ...params,
      }),
    )
  }

  const changeRequestType = (type: StopRequest) => {
    setStopRequest('')
    dispatch(setFormError(undefined))
    setStopRequestType(type)
  }

  const pkStopError = () => !!(stopRequestType === 'pk' && stopRequest.length && !PK_RULE.test(stopRequest))

  const signalStopError = () => !!(stopRequestType === 'signal' && !stopRequest.length)

  const updateStopValue = (value: string) => {
    if (formError) {
      dispatch(setFormError(undefined))
    }

    setStopRequest(value)
  }

  return (
    <div className="communication">
      <div className="main-content">
        <div className="title">
          <button type="button" onClick={goBack}>
            <ChevronIcon width={30} height={30} />
          </button>

          <h3 className="index">{`${terms.Snci.Panel.communication}`}</h3>
        </div>
        {driverCommunication?.currentConditionsVisibility && (<MovingConditions id={id} />)}

        {driverCommunication?.movingAuthorizationVisibility && <MovementInit id={id} />}

        {driverCommunication?.crossingAuthorizationVisibility && <CrossingAuthorization id={id} />}

        {/* demande d'arrêt */}
        <div className="action">
          <h4>{terms.Snci.Panel.Communication.stopRequest}</h4>

          <RadioGroup>
            <FormControlLabel
              value="pk"
              control={(
                <Radio
                  checked={stopRequestType === 'pk' || !!driverCommunication?.stopPK}
                  disabled={hasStopRequest()}
                  onChange={evt => changeRequestType(evt.target.value as 'pk')}
                />
              )}
              label={terms.Snci.Panel.Communication.pkStop}
            />

            <FormControlLabel
              value="signal"
              control={(
                <Radio
                  checked={stopRequestType === 'signal' || !!driverCommunication?.stopSignal}
                  disabled={hasStopRequest()}
                  onChange={evt => setStopRequestType(evt.target.value as 'signal')}
                />
              )}
              label={terms.Snci.Panel.Communication.signalStop}
            />
          </RadioGroup>

          {stopRequestType && (
            <>
              <div className="input">
                {stopRequestType === 'pk' ? (
                  <TextInput
                    placeholder={
                      stopRequestType === 'pk'
                        ? terms.Snci.Panel.Communication.pkPlaceholder
                        : terms.Snci.Panel.Communication.signalPlaceholder
                    }
                    error={pkStopError() || formatToReadablePk(formError?.pk) === stopRequest}
                    errorMessage={
                      formError && formatToReadablePk(formError?.pk) === stopRequest
                        ? terms.Snci.Panel.Communication.pkNotExists : terms.Snci.Panel.Communication.pkError
                    }
                    value={stopRequest}
                    onChange={updateStopValue}
                    disabled={hasStopRequest()}
                  />
                ) : (
                  <CustomAutocomplete
                    disabled={hasStopRequest()}
                    objects={networkObjects.signals}
                    value={stopRequest}
                    placeholder="301"
                    onValueChange={setStopRequest}
                  />
                )}
              </div>

              <Button
                disabled={pkStopError() || signalStopError() || !stopRequest}
                className="submit"
                onClick={sendStopRequest}
              >
                {hasStopRequest() ? terms.Common.cancel : terms.Common.give}
              </Button>
            </>
          )}
        </div>
      </div>
      <button
        type="button"
        className="emergency-stop"
        onClick={sendStopMessage}
        disabled={!driverCommunication?.emergencyStopVisibility}
      >
        <StopIcon />
        {terms.Snci.Panel.Communication.emergencyStop}
      </button>
    </div>
  )
}
