import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MutableRefObject } from 'react'
import {
  LongBeep, ShortBeep, SimulationsState, SvgStream,
  TstTable,
} from 'reducers/types'
import { isLongBeep, isShortBeep } from 'services/snci'
import {
  Direction,
  DriverCommunication,
  PKError,
  SightState, Train, TrainDetails,
} from 'types/snci'

const EMPTY_TRAIN: TrainDetails = {
  trainIndex: '',
  trainId: 0,
  onSightState: SightState.notOnSight,
  pk: '',
  speed: 0,
  direction: Direction.ascending,
  composition: '',
  compositionDetail: '',
  destination: '',
  observation: '',
  rollingStock: '',
  traction: '',
  length: 0,
}
export type SnciState = {
  isOpen: boolean;
  stream: SvgStream;
  simulationState: SimulationsState;
  html: string;
  beep: ShortBeep | LongBeep;
  tstTable: TstTable;
  tstTableData: ArrayBuffer | undefined;
  trainWebsocket: MutableRefObject<WebSocket>;
  trains: Train[];
  trainDetails: TrainDetails;
 formError: PKError;
 driverCommunication: DriverCommunication;
}

const initialState: SnciState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
  html: '',
  beep: undefined,
  tstTable: undefined,
  tstTableData: undefined,
  trainWebsocket: undefined,
  trains: [],
  trainDetails: EMPTY_TRAIN,
  formError: undefined,
  driverCommunication: undefined,

}

const snciSlice = createSlice({
  name: 'snci',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    setHtml: (state, action: PayloadAction<string>) => {
      state.html = action.payload
    },
    setTstTable: (state, action: PayloadAction<TstTable>) => {
      state.tstTable = action.payload
    },
    setTstTableData: (state, action: PayloadAction<ArrayBuffer | undefined>) => {
      state.tstTableData = action.payload
    },
    resetTstTableData: state => {
      state.tstTableData = null
    },
    setBeep: (state, action: PayloadAction<ShortBeep | LongBeep>) => {
      if (!state.beep || isShortBeep(state.beep)) {
        state.beep = action.payload
        return
      }

      if (isLongBeep(state.beep)) {
        if (!state.beep.state || isLongBeep(action.payload)) {
          state.beep = action.payload
        }
      }
    },
    setWebSocket: (state, action: PayloadAction<MutableRefObject<WebSocket>>) => {
      state.trainWebsocket = action.payload
    },
    closeWebSocket: state => {
      if (state.trainWebsocket?.current) {
        state.trainWebsocket?.current.close()
        state.trainWebsocket = undefined
      }
    },
    setTrains: (state, action: PayloadAction<Train[]>) => {
      state.trains = action.payload
    },
    setTrainDetails: (state, action: PayloadAction<Partial<TrainDetails>>) => {
      state.trainDetails = { ...state.trainDetails, ...action.payload }
    },
    resetTrainDetails: state => {
      state.trainDetails = EMPTY_TRAIN
    },
    setFormError: (state, action: PayloadAction<typeof state.formError>) => {
      state.formError = action.payload
    },
    setDriverCommunication: (state, action: PayloadAction<DriverCommunication>) => {
      state.driverCommunication = action.payload
    },
  },
})

export const {
  setOpen: setSnciOpen,
  updateStream: updateSnciStream,
  setHtml: setSnciHtml,
  setBeep,
  setTstTable,
  setTstTableData,
  resetTstTableData,
  setWebSocket: setTrainWebSocket,
  closeWebSocket: closeTrainWebSocket,
  setTrains,
  setTrainDetails,
  resetTrainDetails,
  setFormError,
  setDriverCommunication,
} = snciSlice.actions

export default snciSlice.reducer
