import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import terms from 'assets/localize/terms'
import TrainDetails from 'components/trainDetails/TrainDetails'
import { useAppSelector } from 'hooks'
import { useEffect } from 'react'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import { PanelSignal } from './PanelsManager'
import './TrainList.scss'

export default function TrainList() {
  const { trainWebsocket, trains } = useAppSelector(state => state.snci)

  useEffect(() => {
    trainWebsocket?.current?.send(
      JSON.stringify({
        messageType: SocketMessage.trainManager,
        consumerSpecific: true,
        state: TrainManagerSocketMessage.trainList,
      }),
    )
  }, [])

  const getTrainDetail = (id: string) => {
    PanelSignal.value = <TrainDetails id={id} />
  }

  return (
    <>
      <h3 className="panel-title">{terms.Snci.Panel.trainList}</h3>

      <div className="separator" />
      <div className="train-panel">
        {trains.length
          ? trains.map(train => (
            <button
              key={train.id}
              type="button"
              onClick={() => getTrainDetail(train.id)}
            >
              <div className="info">
                {`${terms.Snci.Panel.train} ${train.index}`}

                <PlayCircleIcon width={18} height={18} />
              </div>
            </button>
          ))
          : terms.Snci.Panel.noTrains}
      </div>
    </>
  )
}
