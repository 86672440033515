import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { ReactComponent as ChevronIcon } from 'assets/icons/rightChevron.svg'
import terms from 'assets/localize/terms'
import { useAppSelector } from 'hooks'
import { PanelSignal } from 'pages/snci/views/PanelsManager'
import TrainList from 'pages/snci/views/TrainList'
import { ReactElement, useEffect } from 'react'
import { SightState } from 'types/snci'
import { SocketMessage, TrainManagerSocketMessage } from 'types/websocket'
import { isDevEnv } from 'utils'
import DriverCommunication from './driverCommunication/DriverCommunication'
import './TrainDetails.scss'

type Props = {
  id: string;
}
export default function TrainDetails({ id }: Props): ReactElement {
  const { trainWebsocket, trainDetails } = useAppSelector(state => state.snci)

  // useEffect(() => () => {
  //   dispatch(resetTrainDetails())
  // }, [])

  const showCommunicationMode = () => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.driverCommunication,
      trainId: id,
    }))
    PanelSignal.value = <DriverCommunication id={id} />
  }

  const changePanel = () => {
    PanelSignal.value = <TrainList />
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainList,
    }))
  }

  useEffect(() => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainManager,
      consumerSpecific: true,
      state: TrainManagerSocketMessage.trainDescription,
      trainId: id,
    }))
  }, [])

  const changeSightMode = (state: SightState) => {
    trainWebsocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.trainOnSight,
      onSight: state === SightState.onSight,
      trainId: id,
    }))
  }
  const nullRepresentation = '-'

  return (
    <div className="train-details">

      <div className="title">
        <button type="button" onClick={changePanel}><ChevronIcon width={30} height={30} /></button>

        <h3 className="index">{`${terms.Snci.Panel.train} ${trainDetails.trainIndex}`}</h3>
      </div>

      <div className="info-wrapper">
        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.rollingStock}</div>
          <div className="value">{trainDetails.rollingStock || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.composition}</div>
          <div className="value">{trainDetails.composition || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.traction}</div>
          <div className="value">{trainDetails.traction || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.length}</div>
          <div className="value">{trainDetails.length || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.destination}</div>
          <div className="value">{trainDetails.destination || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.direction}</div>
          <div className="value">{trainDetails.direction || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.speed}</div>
          <div className="value">{trainDetails.speed ?? nullRepresentation}</div>
        </div>
        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.pk}</div>
          <div className="value">{(trainDetails.pk || nullRepresentation).replace('_', ',')}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.observation}</div>
          <div className="value">{trainDetails.observation || nullRepresentation}</div>
        </div>

        <div className="field-container">
          <div className="label">{terms.Snci.Panel.TrainDetails.compositionDetail}</div>
          <div className="value">{trainDetails.compositionDetail || nullRepresentation}</div>
        </div>

      </div>

      {isDevEnv() && (
      <div className="circulation">
        <span>{terms.Snci.Panel.circulationMode}</span>
        <div className="toggle-switch">
          <button
            type="button"
            disabled={trainDetails.onSightState === SightState.transitionToSight}
            onClick={() => changeSightMode(SightState.notOnSight)}
            className={`option${trainDetails.onSightState === SightState.notOnSight ? ' active' : ''}`}
          >
            {terms.Snci.Panel.notOnSight}
          </button>
          <button
            type="button"
            disabled={trainDetails.onSightState === SightState.transitionToSight}
            onClick={() => changeSightMode(SightState.onSight)}
            className={`option${trainDetails.onSightState === SightState.onSight ? ' active' : ''}`}
          >
            {terms.Snci.Panel.onSight}
          </button>
        </div>
      </div>
      )}

      <button type="button" className="actions" onClick={showCommunicationMode}>
        <div>
          {terms.Snci.Panel.driverCommunication}
          <PlayCircleIcon width={18} height={18} />
        </div>

      </button>

    </div>
  )
}
