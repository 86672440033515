import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import terms from 'assets/localize/terms'
import {
  BroadcastStream, Comments, MessageInput, MessagesFilter, SimulationStream, TimeController, TimeTools,
  VersionInput,
} from 'components'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'
import MessageSection from 'components/messages/MessageSection'
import { speedSignal } from 'components/timeController/TimeController'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { setTcoOpen, setTptOpen } from 'reducers/interfaces/slices'
import {
  setBroadcast, setPause, setSelectedVersion, setStarted, toggleDisplayComments,
} from 'reducers/simulations/slices'
import {
  getCommentSeverityList, getCommentStatusList, getSimulation,
} from 'reducers/simulations/thunks'
import { isDevEnv } from 'utils'
import { isAutoOpenInterfacesEnabled } from 'features'
import CheckBox from './checkBoxes/CheckBox'
import './style.scss'

export default function Logs() {
  const dispatch = useAppDispatch()
  const {
    displayComments, selectedVersion, selectedSimulation, webSocket, broadcast,
  } = useAppSelector(state => state.simulations)
  const {
    isOpen: isSnciOpen,
  } = useAppSelector(state => state.snci)
  const {
    isTcoOpen, isTptOpen,
  } = useAppSelector(state => state.interfaces)
  const [searchQuery, setSearchQuery] = useState('')
  const [checkedItems, setCheckedItems] = useState({
    infoChecked: true,
    debugChecked: false,
    errorChecked: false,
    systemChecked: false,
  })
  const urlParams = useParams<{id: string}>()
  const [queryParams] = useSearchParams()
  const isHistory = queryParams.get('mode') === 'history'
  const isComments = queryParams.get('showComments')
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getCommentStatusList())
    dispatch(getCommentSeverityList())

    if (!selectedSimulation && urlParams.id) {
      dispatch(getSimulation(urlParams.id))
    }
    if (isComments || isHistory) {
      dispatch(toggleDisplayComments(true))
    }
  }, [])

  const openInterface = (screen: string) => () => {
    const params = isHistory ? '/static' : ''
    window.open(
      `${window.location.origin}/${screen}/${selectedSimulation.id}${params}`,
      '',
      `width=${window.innerWidth},height=${window.screen.availHeight}, left=${window.screen.availWidth}`,
    )
  }

  useEffect(() => () => {
    speedSignal.value = 1
    dispatch(setBroadcast(undefined))
    dispatch(setTcoOpen(false))
    dispatch(setTptOpen(false))
    dispatch(setPause(undefined))
    dispatch(setStarted(true))
  }, [])

  useEffect(() => {
    if (!selectedSimulation?.id || broadcast?.current || isTcoOpen
       || isHistory) return
    isAutoOpenInterfacesEnabled().then(enabled => {
      if (enabled) {
        openInterface('tco')()
        openInterface('snci')()
      }
    })
  }, [selectedSimulation])

  const handleDisplayComments = () => {
    navigate(`${window.location.pathname}?showComments=true`)
    dispatch(toggleDisplayComments(true))

    if (!selectedVersion) {
      dispatch(setSelectedVersion(selectedSimulation.scenario.versionId))
      dispatch(getSimulation(selectedSimulation.id))
      return
    }
    dispatch(getSimulation(selectedSimulation.id))
  }

  return (
    <div className="page logs">
      <SimulationStream type="logs" />
      <BroadcastStream />
      <div className="info">
        <VersionInput />
        {isDevEnv() && !displayComments && <TimeTools />}
        <div className="buttons">
          <span>{terms.Logs.openInterfaces}</span>

          <SimpleButton
            disabled={isTcoOpen || !selectedSimulation}
            text={terms.Tco.name}
            onClick={openInterface('tco')}
          />
          <SimpleButton
            disabled={isTptOpen || !selectedSimulation}
            text={terms.Tpt.name}
            onClick={openInterface('tpt')}
          />

          {!isHistory && (
          <SimpleButton
            disabled={isSnciOpen || !selectedSimulation}
            text={terms.Snci.name}
            onClick={openInterface('snci')}
          />
          )}

        </div>
      </div>
      <div className="d-flex h-100" style={{ gap: 20 }}>
        <div className="d-flex flex-column position-relative w-100" style={{ gap: 12 }}>
          <div className="controller">

            <MessagesFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <TimeController />

          </div>
          <MessageSection checkedItems={checkedItems} searchQuery={searchQuery} />
          {!displayComments && !isHistory && <MessageInput />}
          <CheckBox checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
          {!displayComments && !isHistory && (
          <SimpleButton
            text="Commentaires"
            style={ButtonStyle.primary}
            startIcon={<InfoOutlinedIcon />}
            disabled={!webSocket}
            onClick={handleDisplayComments}
            sx={{
              maxWidth: 300, position: 'absolute', bottom: 10, right: 0,
            }}
          />
          )}
        </div>
        <Comments />
      </div>
    </div>
  )
}
